import PasswordValidator from "components/tools/validation/PasswordValidator.js";
import * as validationConstants from '../constants/validationConstants.jsx';

export default {
	validate(prop, value) {
		switch (prop) {
			case "registrationNumber":
				return value.replace(/[^0-9]/g, '');
			case "zipCode":
				return value.replace(/[^0-9]/g, '');
			case "phone":
				return value.replace(/[^0-9]/g, '');
			default:
				return value;
		}
	},
	ifCorrectSymbolsForPlates(input) {

		var letters = /^[0-9a-zA-Z ()-]+$/;
		var result = "";
		for (var i = 0; i < input.length; i++) {
			var char = input.charAt(i);

			if (char.match(letters)) {
				result += char;
			}
		}
		return result;
	},
	ifNumber(input) {
		var result = "";
		for (var i = 0; i < input.length; i++) {
			var char = input.charAt(i);

			if (/\s/g.test(char)) {
				result += char;
			}

			if (isNaN(char)) {
				result += char;
			}
		}
		return result;
	},
	blockSpecialChar(value) {
		return value.replace(/['`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '');
	},
	passwordSatisfiesRequirements(input) {
		const {
			isMinLengthRuleSatisfied,
			isUppercaseLetterRuleSatisfied,
			isLowercaseLetterRuleSatisfied,
			isDigitRuleSatisfied,
			isSpecialSymbolRuleSatisfied
		} = PasswordValidator.getRuleSatisfactionResult(input);

		return isMinLengthRuleSatisfied
			&& isUppercaseLetterRuleSatisfied
			&& isLowercaseLetterRuleSatisfied
			&& isDigitRuleSatisfied
			&& isSpecialSymbolRuleSatisfied;
	},
	validateMandatoryField(prop, value, legalEntity, b2b, validations) {

		let b2bValidations = b2b === true ? true : false;
		var errors = [];

		if (prop === 'name' || prop === 'firstName') {
			if (value === null || value === undefined || value.length === 0) {
				errors.push("profile_information_page_mandatory_field_error");
			}
		}
		else if (prop === 'gender') {
			if (value === null || value === undefined || value.length === 0) {
				errors.push("add_your_data_page_select_gender_error");
			}
		}
		else if (prop === 'lastName' || prop === 'email' || prop === 'Company name'
			|| prop === 'companyName'
			|| prop === 'city' || prop === 'legalAddress'
			|| prop === 'zipCode') {
			if (value === null || value === undefined || value.length === 0) {
				errors.push("profile_information_page_mandatory_field_error");
			} else if (prop === 'companyName' && value.length > validationConstants.COMPANY_NAME_MAX_LENGTH) {
				errors.push('add_your_data_page_mandatory_field_error');
			} else if (prop === 'city' && value.length > validationConstants.CITY_MAX_LENGTH) {
				errors.push('add_your_data_page_mandatory_field_error');
			} else if (prop === 'legalAddress' && value.length > validationConstants.LEGAL_ADDRESS_MAX_LENGTH) {
				errors.push('add_your_data_page_mandatory_field_error');
			}
		}
		else if (prop === 'registrationNumber') {
			if (value === null || value === undefined || value.length === 0) {
				errors.push("add_your_data_page_mandatory_field_error");
			}
			else if (value.length < validations.b2bMinRegistrationNumberLength ||
				value.length > validations.b2bMaxRegistrationNumberLength) {
				errors.push("b2b_profile_information_page_empty_reg_number_error");
			}
		}
		else if (prop === 'zipCode') {
			let minLength = legalEntity === "2" ? 4 : 5;
			if (!b2bValidations) {
				if ((value === null || value === undefined) || value.length !== 0 && value.length < minLength) {
					errors.push("this field must be minimum " + minLength + " symbols length");
				}
			}
			else {
				if (value !== null && value !== undefined && value.length !== 0 && value.length < minLength) {
					errors.push("this field must be minimum " + minLength + " symbols length");
				}
			}
		}
		else if (prop === 'vatRegistrationNumber') {
			if (value !== null && value !== undefined && value.length !== 0 && value.length < 9) {
				errors.push("add_your_data_page_vat_registration_field_error");
			}
		}
		else if (prop === 'phone') {
			if (value === null || value === undefined || value.length === 0) {
				errors.push("add_your_data_page_mandatory_field_error");
			}
		}

		return errors;
	},
	validateBirthDate(
		birthDate,
		validations,
		maxAgeError = "add_your_data_page_birthdate_error",
		minAgeError = "add_your_data_page_birthdate_error",
		emptyFieldError = "enter_additional_information_page_empty_birthdate_field_error"
	) {
		var errors = [];
		if (birthDate === undefined || birthDate === null || birthDate === "" || !isNaN(birthDate)) {
			errors.push(emptyFieldError);
		}
		else {
			let presentDate = new Date();
			let minAge = new Date(presentDate.getUTCFullYear() - validations.minAge, presentDate.getUTCMonth(), presentDate.getUTCDate(), 0, 0, 0);
			let maxAge = new Date(presentDate.getUTCFullYear() - validations.maxAge, presentDate.getUTCMonth(), presentDate.getUTCDate(), 0, 0, 0);

			const parsedDate = new Date(birthDate);

			if (parsedDate.getTime() > minAge.getTime()) {
				errors.push(minAgeError)
			}
			else if (parsedDate.getTime() < maxAge.getTime()) {
				errors.push(maxAgeError);
			}
		}
		return errors;
	},
	blockUnwantedSymbols(name) {
		var result = this.blockSpecialChar(this.ifNumber(name));
		if (name.length === 1) {
			return result.trim().replace('-', '');
		}
		return result;
	},
	blockSpaceSymbol(input) {
		return input.replace(/\s/g, "");
	},
	validateCarPlate(numberPlate) {
		return this.blockSpecialChar(this.ifCorrectSymbolsForPlates(numberPlate)).replace('-', '').replace(/\s/g, '');
	},
	validateNameBeforeSubmit(name) {
		var errors = [];
		let nameProcessed = (name !== null && name !== undefined) ? name.replace(/\s/g, "") : null;

		if (nameProcessed === null || nameProcessed === "") {
			errors.push("add_your_data_page_mandatory_field_error");
		}
		return errors;
	},
	validatePassword(password) {
		const isValid = this.passwordSatisfiesRequirements(password);
		return !isValid
			? ["add_your_data_page_pwd_rules_error"]
			: [];
	},
	validatePasswords(password, confirmPassword, errorMessage = null) {
		var errors = [];
		password = password || "";
		confirmPassword = confirmPassword || "";

		if (password !== confirmPassword) {
			if (errorMessage) {
				errors.push(errorMessage);
			} else {
				errors.push("add_your_data_page_pwd_not_match_error");
			}
		}

		return errors;
	},
	getPhoneMinLength(phonePrefixes, selectedPrefix) {
		let validations = phonePrefixes.filter(function (prefix) {
			return prefix.value === selectedPrefix
		});

		let minLength = parseInt(validations[0].minLength);
		let length = parseInt(validations[0].length);

		if (minLength !== null && minLength !== undefined && minLength > 0 && minLength > length) {
			return minLength.toString();
		}
		else {
			return length.toString();
		}
	},
	validatePhonePrefixes(phonePrefixes, selectedPrefix, phoneNumber) {
		let validations = phonePrefixes.filter(function (prefix) {
			return prefix.value === selectedPrefix
		});

		let minLength = parseInt(validations[0].minLength);
		let length = parseInt(validations[0].length);

		if (minLength !== null && minLength !== undefined && minLength > 0) {
			if (minLength < length) {
				if ((phoneNumber === null || phoneNumber === undefined) || phoneNumber.length < minLength || phoneNumber.length > length) {
					return "password_recovery_page_incorrect_phone_field_error";
					// return "phone must have " + minLength + " or " + length + " symbols";
				}
			}
			else {
				if ((phoneNumber === null || phoneNumber === undefined) || phoneNumber.length < length || phoneNumber.length > minLength) {
					return "password_recovery_page_incorrect_phone_field_error";
					// return "phone must have " + length + " or " + minLength + " symbols";
				}
			}
		}
		else {
			if ((phoneNumber === null || phoneNumber === undefined) || phoneNumber.length < length) {
				return "password_recovery_page_incorrect_phone_field_error";
				// return "phone must have " + length + " symbols";
			}
		}

		if ((phoneNumber === null || phoneNumber === undefined) || phoneNumber.length > length) {
			return "password_recovery_page_incorrect_phone_field_error";
			// return "phone must have " + length + " symbols";
		}

		return "";
	},
	validateSeniorIdNumber(seniorIdNumber) {
		var re = /^[A-Za-z]{1,2}[\d]{6,8}$/;
		var isSeniorIdNumberValid = re.exec(seniorIdNumber);

		return isSeniorIdNumberValid ? "" : "senior_club_page_incorrect_nr_error";
	}
};
